@import url(./font.css);
@import url(./layout.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* user-select: none; */
  -webkit-touch-callout: none;
}
.fixed-button {
  position: fixed !important;
  z-index: 10 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  right: 0 !important;
  border-radius: 0px !important;
}

.img-button {
  align-self: flex-end;
}
.img-button img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.small-icon {
  width: 13px;
  height: 13px;
  object-fit: contain;
}

.icon {
  width: 16px;
  object-fit: contain;
  height: 16px;
}
.margin {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.input-border {
  border: 1px solid #fe6e0e;
}
.shadow {
  -webkit-box-shadow: 1px 3px 5px 0px rgba(200, 200, 200, 1);
  -moz-box-shadow: 1px 3px 5px 0px rgba(200, 200, 200, 1);
  box-shadow: 1px 3px 5px 0px rgba(200, 200, 200, 1);
  background-color: white;
}
.shadow2 {
  -webkit-box-shadow: 3px 1px 3px 1px rgba(200, 200, 200, 1);
  -moz-box-shadow: 3px 1px 3px 1px rgba(200, 200, 200, 1);
  box-shadow: 3px 1px 3px 1px rgba(200, 200, 200, 1);
  background-color: white;
}
.shadow-small {
  -webkit-box-shadow: 1px 2px 4px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 1px 2px 4px 0px rgba(230, 230, 230, 1);
  box-shadow: 1px 2px 4px 0px rgba(230, 230, 230, 1);
  background-color: white;
}
.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
span {
  font-family: "NotoSansKR-Regular";
}
/* @font-face {
  font-family: "NotoSansKR-Bold";
  src: local("NotoSansKR-Bold"),
    url(./assets/fonts/NotoSansKR-Bold.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansKR-Light";
  src: local("NotoSansKR-Light"),
    url(./assets/fonts/NotoSansKR-Light.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansKR-Medium";
  src: local("NotoSansKR-Medium"),
    url(./assets/fonts/NotoSansKR-Medium.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansKR-Regular";
  src: local("NotoSansKR-Regular"),
    url(./assets/fonts/NotoSansKR-Regular.otf) format("opentype");
} */

.notice_content img {
  width: 100%;
}
.faqContent img {
  width: 100%;
}

.search_new {
  position: fixed;
  background: #fff;
  padding: 1.1rem 1.5278rem;
  width: 100%;
  border-bottom: 1px solid #e0e1e2;
  z-index: 10;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.search_new #topsearch {
  position: relative;
  vertical-align: middle;
  background: #fff;
  border-radius: 5px;
}
.search_new #topsearch input {
  border: none;
}
.search_new #topsearch input.bt {
  position: absolute;
  left: 1.2222rem;
  top: 50%;
  margin-top: -0.7333rem;
  width: 1.5278rem;
  height: 1.5278rem;
}
.search_new #topsearch img {
  width: auto;
  height: 1.5278rem;
}
.search_new #topsearch input.ft_noti.ph {
  background-color: #fafafa;
  border: 1px solid #ededed;
  border-radius: 30px;
  width: 100%;
  height: 3.1167rem;
  vertical-align: middle;
  padding: 0 2.1389rem 0 3.2389rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.search_new #topsearch input::placeholder {
  color: #c7c7c7;
}
.img_noti {
  width: auto;
  height: 1.1611rem;
  vertical-align: middle;
}

.ft_tab_box {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 20px 0;
}
.ft_tab.on {
  color: #222222;
  background-color: #f4f4f4;
  border-radius: 14px;
  padding: 7px 10px 7px 10px;
}
.ft_tab.false {
  padding: 7px 10px 7px 10px;
}
.ft_wrap {
  margin: 0px;
  padding: 0 20px;
}
.ft_wrap_box {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 5px #f0f0f0;
  margin: 20px 0;
  padding: 0.6111rem;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}
.ft_wrap_img {
  position: relative;
}
.ft_event_img {
  width: 100%;
}

.ft_wrap_img .ft_into_tit.cof {
  position: absolute;
  right: 0px;
  bottom: 0;
  margin-bottom: -2px;
  background: url(../images/img_tr1.png) no-repeat bottom right;
  padding: 0 5px 3px 0;
  background-size: contain;
  width: 66px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.ft_wrap_img .ft_into_tit {
  position: absolute;
  right: 0px;
  bottom: 0;
  background: url(../images/img_tr2.png) no-repeat bottom right;
  padding: 0 5px 3px 0;
  background-size: contain;
  width: 66px;
  height: 48px;
  color: #fff;
  font-size: 12px;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.ft_wrap_img .ft_event_img_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  border-radius: 5px;
}
.ft_box_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6111rem 0 0 0;
}
.ft_box_btn img {
  width: 5px;
  height: 11px;
}

.ft_tab.none {
  padding: 0 1.5278rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ft_tab.none img {
  width: 2.4444rem;
}
.ft_tab.none_txt {
  text-align: center;
  padding: 0.9167rem 0 0 0;
}
.ft_tab.none h2 {
  font-size: 1.0389rem;
  color: #222222;
}
.ft_tab.none p {
  font-size: 0.7944rem;
  color: #000000;
}

.ft_spo.date {
  font-size: 0.7944rem;
  letter-spacing: 0.025rem;
  color: #999;
}
.ft_body {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 0.7944rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.025rem;
  line-height: 1.1611rem;
  color: #000 !important;
}
.ft_body.lg {
  letter-spacing: 0.05rem;
}

.img_new {
  width: auto;
  height: 0.9167rem;
  margin-left: 0.3056rem;
  vertical-align: middle;
}

.home_noti {
  width: 100%;
  position: relative;
  padding-top: 1.1rem;
  padding-bottom: 0.8556rem;
}
.home_noti img {
  width: auto;
  height: 1.1rem;
  vertical-align: middle;
}
.home_noti .ic01 {
  margin-right: 0.3056rem;
}
.home_noti .ic02 {
  position: absolute;
  right: 0;
}
.swiper-paging
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  bottom: -3px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  padding-left: 100%;
  right: 0;
}

input[type="date"] {
  position: relative;
}

div.react-calendar {
  width: 100%;
}
